<template>
  <v-layout>
    <v-card v-if="isDataPassed" :elevation="0">
      <!-- <v-card-title class="text-center">
        V3-
        <img src="/static/image/payment/surepay-logo.png"
             :aspect-ratio="200/55" width="90" height="auto"/>
        &nbsp; secure redirection in <span style="color:red;">&nbsp;{{ countDown }}s&nbsp;</span> , please do not
        refresh
        the page
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-card-title> -->
      <!-- <form ref="paymentDialog" id="paymentForm" method="post" :action="secureObj.payment_url" >
      <input type="hidden" name="MerchantCode" :value="secureObj.MerchantCode" />
      <input type="hidden" name="CustomerCode" :value="secureObj.CustomerCode" />
      <input type="hidden" name="PhoneNumber" :value="secureObj.PhoneNumber" />
      <input type="hidden" name="Currency" :value="secureObj.Currency" />
      <input type="hidden" name="BankCode" :value="secureObj.BankCode" />
      <input type="hidden" name="Amount" :value="secureObj.Amount" />
      <input type="hidden" name="LanguageCode" :value="secureObj.LanguageCode" />
      <input type="hidden" name="SourceAccNo" :value="secureObj.SourceAccNo" />
      <input type="hidden" name="TransactionId" :value="secureObj.TransactionId" />
      <input type="hidden" name="Reference" :value="secureObj.Reference" />
      <input type="hidden" name="Timestamp" :value="secureObj.Timestamp" />
      <input type="hidden" name="ClientIP" :value="secureObj.ClientIP" />
      <input type="hidden" name="Signature" :value="secureObj.Signature" />
      <input type="hidden" name="ResponseURL" :value="secureObj.ResponseURL" />
      <input type="hidden" name="BackendURL" :value="secureObj.BackendURL"/>
      </form> -->
      <v-form method="post" :action="secureObj.payment_url"
              ref="paymentDialog" id="paymentForm">
        <v-row class="mx-5">
          <template>
            <v-col cols="12" class="pb-0 pt-0" v-show="false">
              <v-text-field name="MerchantCode"
                            label="MerchantCode"
                            readonly
                            :value="secureObj.MerchantCode"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0" v-show="false">
              <v-text-field name="CustomerCode"
                            label="CustomerCode"
                            readonly
                            :value="secureObj.CustomerCode"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0" v-show="false">
              <v-text-field name="PhoneNumber"
                            label="PhoneNumber"
                            readonly
                            :value="secureObj.PhoneNumber"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0" v-show="false">
              <v-text-field name="Currency"
                            label="Currency"
                            readonly
                            :value="secureObj.Currency"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0" v-show="false">
              <v-text-field name="BankCode"
                            label="BankCode"
                            readonly
                            :value="secureObj.BankCode"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0" v-show="false">
              <v-text-field name="Amount"
                            label="Amount"
                            readonly
                            :value="secureObj.Amount"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0" v-show="false">
              <v-text-field name="LanguageCode"
                            label="LanguageCode"
                            readonly
                            :value="secureObj.LanguageCode"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" v-show="false">
              <v-text-field name="SourceAccNo"
                            label="SourceAccNo"
                            readonly
                            :value="secureObj.SourceAccNo"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" v-show="false">
              <v-text-field name="TransactionId"
                            label="TransactionId"
                            readonly
                            :value="secureObj.TransactionId"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" v-show="false">
              <v-text-field name="Reference"
                            label="Reference"
                            readonly
                            :value="secureObj.Reference"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" v-show="false">
              <v-text-field name="Timestamp"
                            label="Timestamp"
                            readonly
                            :value="secureObj.Timestamp"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" v-show="false">
              <v-text-field name="ClientIP"
                            label="ClientIP"
                            readonly
                            :value="secureObj.ClientIP"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" v-show="false">
              <v-text-field name="Signature"
                            label="Signature"
                            readonly
                            :value="secureObj.Signature"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" v-show="false">
              <v-text-field name="ResponseURL"
                            label="ResponseURL"
                            readonly
                            :value="secureObj.ResponseURL"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" v-show="false">
              <v-text-field name="BackendURL"
                            label="BackendURL"
                            readonly
                            :value="secureObj.BackendURL"></v-text-field>
            </v-col>
          </template>
        </v-row>
      </v-form>
      <!-- <v-card-text class="text-center">
        You'll be redirected to our secure payment page. All payment transactions are encrypted and protected.
      </v-card-text>
      <v-col cols="12" :xl="6" :lg="6" class="mt-2 mx-auto">
        <v-btn width="100%" color="primary" @click="submit">Proceed to payment
        </v-btn>
      </v-col> -->
    </v-card>
    <v-card v-else :elevation="0">
      <v-card-title class="pb-0">
        You're not authorized to view this page, please resubmit your payment again !
      </v-card-title>
      <v-col cols="12" :xl="6" :lg="6" class="mt-2 mx-auto">
        <v-btn width="100%" color="primary" :to="{name: routeName.HOME}">Back to Home Page
        </v-btn>
      </v-col>
    </v-card>
  </v-layout>
</template>

<script>
import {SHARED_LOADING} from "@/store/shared.module";
import {ROUTE_NAME} from '@/constants/route.constants';

export default {
  name: "paysecure",
  data: () => ({
    routeName: ROUTE_NAME,
    isDataPassed: false,
    countDown: 1,
    secureObj: {
      Amount: '',
      MerchantCode: '',
      CustomerCode: '',
      PhoneNumber: '',
      Currency: '',
      BankCode: '',
      LanguageCode: '',
      SourceAccNo: '',
      TransactionId: '',
      Reference: '',
      Timestamp: '',
      ClientIP: '',
      Signature: '',
      ResponseURL: '',
      BackendURL: ''
    }
  }),
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1)
      } else {
        this.submit()
      }
    },
    submit() {
      this.$ga.event('DPay', 'Submit', 'Pending', 1)
      this.$store.dispatch(`${SHARED_LOADING}`)
      paymentForm.submit()
    },
    onSubmit () {
      console.log("after submit")
    }
  },
  created() {
    let obj = this.$route.query
    this.isDataPassed = !_.isEmpty(obj)
    this.secureObj = {
      Amount: obj.Amount,
      MerchantCode: obj.MerchantCode,
      CustomerCode: obj.CustomerCode,
      PhoneNumber: obj.PhoneNumber.padStart(11, '0'),
      Currency: obj.Currency,
      BankCode: obj.BankCode,
      LanguageCode: obj.LanguageCode,
      SourceAccNo: obj.SourceAccNo.padStart(11, '0'),
      TransactionId: obj.TransactionId,
      Reference: obj.Reference,
      Timestamp: obj.Timestamp,
      ClientIP: obj.ClientIP,
      Signature: obj.Signature,
      ResponseURL: obj.ResponseURL,
      BackendURL: obj.BackendURL,
      payment_url: obj.payment_url
    };
    if(this.isDataPassed){
      this.countDownTimer()
    }
  }
}
</script>

<style scoped>

</style>